





















/*
	출결 상태 레이블
	0: 출석
	1: 지각
	2: 결석
	3: 사용자화
*/
import Vue from "vue";
import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

import AttendanceSheetTable from "../../components/Community/AttendanceSheetTable.vue";

export default Vue.use(VueSocketIOExt, io("https://circles-api.hyunwoo.dev/")).extend({
	sockets: {
		attendance_updateAttendance(this: any, data) {
			this.datas = data.data.datas;
			this.dates = data.data.dates;
		},
		attendance_createAttendance(this: any, data) {
			this.datas = data.data.datas;
			this.dates = data.data.dates;
		},
		attendance_deleteAttendance(this: any, data) {
			this.$socket.client.emit("attendance_createAttendance", {
				clubname: this.getClub.name,
			});
		},
		attendance_getAttendanceByClubName(this: any, data) {
			console.log(data);
			if (data.result) {
				this.datas = data.data.datas;
				this.dates = data.data.dates;
			} else {
				this.$socket.client.emit("attendance_createAttendance", {
					clubname: this.getClub.name,
				});
			}
		},
	},
	components: {
		AttendanceSheetTable,
	},
	data() {
		return {
			colors: {
				red: "#db4437",
				orange: "#fe9900",
				yellow: "#f5bf4f",
				green: "#0f8f50",
				blue: "#4682f4",
				purple: "#6834b7",
				black: "#444",
			},
			datas: [] as any,
			dates: [] as any,
			showEditor: false,
			currentId: "",
			isReloading: false,
		};
	},
	created() {
		this.$socket.client.emit("attendance_getAttendanceByClubName", {
			clubname: this.getClub.name,
		});
	},
	watch: {
		datas() {
			if (this.datas.length > 0) {
				this.isReloading = false;
			}
		},
	},
	methods: {
		reload() {
			this.isReloading = true;
			this.datas = [];
			this.dates = [];
			this.$socket.client.emit("attendance_deleteAttendance", {
				clubname: this.getClub.name,
			});
		},
		changeState(e: any) {
			let idx = this.datas.findIndex((x) => x._id == e.id);
			this.datas[idx].attendance[e.day].state = e.state;
			if (e.state !== 3) this.datas[idx].attendance[e.day].comment = ``;
		},
		update() {
			this.$socket.client.emit("attendance_updateAttendance", {
				clubname: this.getClub.name,
				dates: this.dates,
				datas: this.datas,
			});
		},
		createAttendanceSheet() {
			this.dates.push({});
		},
	},
	computed: {
		getClub() {
			return this.$store.state.club;
		},
	},
});
