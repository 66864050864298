








































import Vue from "vue";
export default Vue.extend({
	props: {
		colors: Object,
		dates: Array,
		data: Object
	},
	methods: {
		changeState(id: string, day: number, state: number) {
			this.$emit("changeState", { id: id, day: day, state: state });
		},
		getState(day: number): string {
			let output = "";
			let state = this.data.attendance[day].state;
			switch (state) {
				case 0:
					output = "state0 mdi mdi-circle-outline";
					break;
				case 1:
					output = "state1 mdi mdi-triangle-outline";
					break;
				case 2:
					output = "state2 mdi mdi-close";
					break;
			}
			return output;
		}
	},
	computed: {
		getRole() {}
	}
});
