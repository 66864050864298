


































import Vue from "vue";
import AttendanceSheetItem from "./AttendanceSheetItem.vue";
export default Vue.extend({
	components: {
		AttendanceSheetItem
	},
	props: {
		colors: Object,
		dates: Array,
		datas: Array
	},
	data() {
		return {
			sortKey: "",
			sortBy: false
		};
	},
	methods: {
		orderBy(key: string) {
			if (this.sortKey == key) {
				this.sortBy = !this.sortBy;
			} else {
				this.sortKey = key;
			}
		},
		changeState(e: any) {
			this.$emit("changeState", { id: e.id, day: e.day, state: e.state });
		},
		changeDate(e: KeyboardEvent, idx: number) {
			let next = (e.target as HTMLInputElement).value;
			let prevString = "" + (this.dates[idx] as any).date;
			(this.dates[idx] as any).date = next.toString();
			this.datas.forEach(member => {
				let idx = (member as any).attendance.findIndex(
					x => x.name == prevString
				);
				(member as any).attendance[idx].name = next.toString();
			});
			this.$emit("change", e);
		},
		stringToDate(str: string) {
			console.log(new Date(str));
			return new Date(str);
		}
	},
	computed: {
		getOrderedItems(): any {
			if (this.sortKey == "") {
				return this.datas;
			} else {
				return this.datas.sort((a: any, b: any): any => {
					if (this.sortBy)
						return b[this.sortKey] > a[this.sortKey] ? 1 : -1;
					else return a[this.sortKey] > b[this.sortKey] ? 1 : -1;
				});
			}
		}
	}
});
